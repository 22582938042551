import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { CalendarHeaderComponent, HeaderComponent } from '../components';
import { DataService } from '../services';

import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        NgbNavModule, 
        RouterModule,
        MatIconModule
    ],
    declarations: [
        HeaderComponent,
        CalendarHeaderComponent
    ],
    providers: [DataService],
    exports: [
        HeaderComponent,
        CalendarHeaderComponent
    ]
})
export class SharedModule { }
