<div *ngIf="showHeader">
  <app-header></app-header>
  <div class="tempo-container">
    <div class="tempo-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div *ngIf="!showHeader">
  <router-outlet></router-outlet>
</div>